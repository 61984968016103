import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Link as MaterialLink, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const About = lazy(() => import('./About'));
const Home = lazy(() => import('./Home'));
const AddItem = lazy(() => import('./AddItem/AddItem'));
const AddTips = lazy(() => import('./AddTips/AddTips'));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <MaterialLink color="inherit" href="https://material-ui.com/">
        cook-tip.com
    </MaterialLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const App: React.FC = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/addItem">Add Item</Link>
          </li>
          <li>
            <Link to="/addTips">Add Tips</Link>
          </li>
        </ul>
      </nav>
      <Switch>
        <Route path="/about"><About /></Route>
        <Route path="/addItem"><AddItem /></Route>
        <Route path="/addTips"><AddTips /></Route>
        <Route path="/"><Home /></Route>
      </Switch>
      <footer>
        <Box mt={5}>
          <Copyright />
        </Box>
      </footer>
    </Suspense>
  </Router>
);

export default App;
